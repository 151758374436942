@import '~antd/dist/antd.css';

@font-face {
    font-family: Calibri;
    src: url('assets/fonts/calibri-regular.ttf') format("truetype"); /* Safari, Android, iOS */
}

@font-face {
    font-family: Calibri;
    font-weight: bold;
    src: url('assets/fonts/calibri-bold.ttf') format("truetype"); /* Safari, Android, iOS */
}
